import React from "react";

import BingoCard from "./BingoCard";
import BingoCardForm from "./BingoCardForm";
import OtherBingo from "./OtherBingo";

export const Bingo = () => {
  const [bingoCard, setBingoCard] = React.useState(null);

  return (
<div style={{ height: "100%", display: "flex", flexDirection: "column", alignItems: 'center', justifyContent: 'center' }}>
  {!bingoCard && <BingoCardForm setBingoCard={setBingoCard} />}
  <div style={{ padding: "8px", paddingTop: '24px' }}>
    <BingoCard bingoCard={bingoCard} />
  </div>
  <div style={{ position: "fixed", bottom: 0, left: 0, width: "100%" }}>
    {<OtherBingo bingoCard={bingoCard} />}
  </div>
</div>

  );
};
