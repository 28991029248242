import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';

export const EditInterview = ({
  questions,
  setQuestions,
  person1,
  person2,
}) => {
  const [currentQuestion, setCurrentQuestion] = useState('');
  const [currentAnswer, setCurrentAnswer] = useState('');
  const [selectedPerson, setSelectedPerson] = useState('');
  const [editMode, setEditMode] = useState(null);

  const handleQuestionChange = (e) => {
    setCurrentQuestion(e.target.value);
  };

  const handleAnswerChange = (e) => {
    setCurrentAnswer(e.target.value);
  };

  const handlePersonChange = (e) => {
    setSelectedPerson(e.target.value);
  };

  const handleAddQuestion = () => {
    if (currentQuestion && currentAnswer && selectedPerson) {
      const newQuestion = {
        question: currentQuestion,
        answer: currentAnswer,
        personId: selectedPerson,
      };

      setQuestions([...questions, newQuestion]);
      setCurrentQuestion('');
      setCurrentAnswer('');
      setSelectedPerson('');
    }
  };

  const handleEditQuestion = (index) => {
    setEditMode(index);
    setCurrentQuestion(questions[index].question);
    setCurrentAnswer(questions[index].answer);
    setSelectedPerson(questions[index].personId);
  };

  const handleUpdateQuestion = () => {
    if (currentQuestion && currentAnswer && selectedPerson && editMode !== null) {
      const updatedQuestions = [...questions];
      updatedQuestions[editMode] = {
        question: currentQuestion,
        answer: currentAnswer,
        personId: selectedPerson,
      };

      setQuestions(updatedQuestions);
      setEditMode(null);
      setCurrentQuestion('');
      setCurrentAnswer('');
      setSelectedPerson('');
    }
  };

  const handleRemoveQuestion = (index) => {
    const updatedQuestions = [...questions];
    updatedQuestions.splice(index, 1);
    setQuestions(updatedQuestions);
  };

  return (
    <div>
      {questions.map((q, index) => (
        <div key={index} style={{ marginBottom: '16px' }}>
          <Typography variant="h6">Question {index + 1}</Typography>
          <Typography variant="body1">Question: {q.question}</Typography>
          <Typography variant="body1">Answer: {q.answer}</Typography>
          <Typography variant="body1">Person: {q.personId === person1._id ? person1.name : person2.name}</Typography>
          <Button variant="outlined" onClick={() => handleEditQuestion(index)}>
            Edit Question
          </Button>
          <Button variant="outlined" onClick={() => handleRemoveQuestion(index)}>
            Remove Question
          </Button>
        </div>
      ))}

      <TextField
        label="Interview Question"
        variant="outlined"
        fullWidth
        margin="normal"
        value={currentQuestion}
        onChange={handleQuestionChange}
      />

      <TextField
        label="Answer"
        variant="outlined"
        fullWidth
        multiline
        rows={4}
        margin="normal"
        value={currentAnswer}
        onChange={handleAnswerChange}
      />

      <Select
        label="Person Answering"
        variant="outlined"
        fullWidth
        margin="normal"
        value={selectedPerson}
        onChange={handlePersonChange}
      >
        <MenuItem value={person1._id}>{person1.name}</MenuItem>
        <MenuItem value={person2._id}>{person2.name}</MenuItem>
        {/* Add more MenuItem components as needed */}
      </Select>

      {editMode !== null ? (
        <Button variant="contained" color="primary" onClick={handleUpdateQuestion}>
          Update Question
        </Button>
      ) : (
        <Button variant="contained" color="primary" onClick={handleAddQuestion}>
          Add Question
        </Button>
      )}
    </div>
  );
};

