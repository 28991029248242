import React from 'react';
import { styled } from '@mui/material/styles';
import { grey } from '@mui/material/colors';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { Avatar, Button, Drawer, Grid, ListItemIcon } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit'; // Example icon, you can replace it with your desired icon
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import { getColor } from '../../Graph/utils';


const drawerBleeding = 56;

const imageStyle = {
  width: 100,
  height: 100,
  display: 'block',
};

const Root = styled('div')(({ theme }) => ({
  height: '100%',
  backgroundColor:
    theme.palette.mode === 'light' ? grey[100] : theme.palette.background.default,
}));

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'light' ? '#fff' : grey[800],
}));

const Puller = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 8,
  left: 'calc(50% - 15px)',
  zIndex: 2500,
}));

function PersonDrawer({
  personSelected,
  onClose,
  idToPerson,
  onEdgeClick,
  open,
  toggleDrawer,
  edges,
}) {
  const drawerBleeding = personSelected ? 56 : 0;
  console.log({edges})
  return (
    <Root>
      <Drawer
        anchor="bottom"
        open={open}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        swipeAreaWidth={drawerBleeding}
        disableSwipeToOpen={false}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <StyledBox
          onClick={toggleDrawer(!open)}
          sx={{
            position: 'absolute',
            top: -(personSelected ? 56 : 0),
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            visibility: 'visible',
            right: 0,
            left: 0,
            zIndex: personSelected ? 2000 : -2000,
            backgroundColor: getColor(personSelected?._id),
            transition: 'top 0.2s ease-out',
          }}
        >
          <Puller>
            <IconButton onClick={toggleDrawer(!open)}>
              {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </Puller>
          <Typography
            sx={{ p: 2, color: 'text.secondary', fontWeight: 'bold' }}
          >
            {personSelected?.name}
          </Typography>
        </StyledBox>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: getColor(personSelected?._id),
            paddingBottom: '16px',
            paddingRight: '10px',
            paddingLeft: '10px',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Avatar
            alt={personSelected?.name}
            src={
              personSelected?.imageURL ||
              `https://robohash.org/${personSelected?.name}`
            }
            sx={imageStyle}
          />
          <Typography
            sx={{ p: 2, color: 'text.secondary', fontWeight: 'bold' }}
          >
            {console.log(personSelected) || personSelected?.funFact || 'FUN FACT GOES HERE'}
          </Typography>
        </Box>
        <StyledBox
          sx={{
            pb: 2,
            height: '100%',
            overflow: 'auto',
            zIndex: 2000,
          }}
        >
          <Box sx={{ padding: '10px', gap: '2' }}>
            <Typography
              sx={{ p: 2, color: 'text.secondary', fontWeight: 'bold' }}
            >
              Stories:
            </Typography>
            {edges?.map((edge) => (
              <Button
                onClick={() => onEdgeClick(edge)}
                sx={{
                  display: 'flex',
                  backgroundColor: `${getColor(
                    edge?.person1 !== personSelected?._id
                      ? edge?.person1
                      : edge?.person2
                  )}`,
                  marginBottom: '10px',
                  width: '100%',
                  alignItems: 'left',
                  justifyContent: 'space-between',
                }}
              >
                <Grid container>
                  <Grid
                    item
                    xs={10}
                    sx={{ display: 'flex', justifyContent: 'flex-start' }}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Avatar
                        alt={
                          idToPerson[
                            edge?.person1 !== personSelected?._id
                              ? edge?.person1
                              : edge?.person2
                          ]?.name
                        }
                        src={
                          idToPerson[
                            edge?.person1 !== personSelected?._id
                              ? edge?.person1
                              : edge?.person2
                          ]?.imageURL ||
                          `https://robohash.org/${
                            idToPerson[
                              edge?.person1 !== personSelected?._id
                                ? edge?.person1
                                : edge?.person2
                            ]?.name
                          }`
                        }
                        sx={imageStyle}
                      />
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                      }}
                    >
                      <Typography
                        sx={{
                          p: 2,
                          color: 'text.secondary',
                          fontWeight: 'bold',
                          textTransform: 'none',
                        }}
                      >
                        Met:{' '}
                        {edge?.dateMet
                          ? new Date(edge?.dateMet).toLocaleDateString(
                              'en-US',
                              {
                                year: 'numeric',
                                month: 'long',
                                day: 'numeric',
                              }
                            )
                          : ''}
                      </Typography>
                      <Typography
                        sx={{
                          p: 2,
                          color: 'text.secondary',
                          fontWeight: 'bold',
                          textTransform: 'none',
                        }}
                      >
                        TLDR: {edge?.notes}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                    }}
                  >
                    <ArrowForwardIosIcon />
                  </Grid>
                </Grid>
              </Button>
            ))}
          </Box>
        </StyledBox>
      </Drawer>
    </Root>
  );
}

export default PersonDrawer;