import { styled } from '@mui/system';

export const StyledModal = styled('Modal')(({ theme }) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "350px",
    height: '90%',
    minHeight: '500px',
    backgroundColor: theme.palette.background.paper, // Fix bgcolor to backgroundColor
    boxShadow: theme.shadows[24], // Use theme.shadows array for boxShadow
        display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  }))

export const StyledModalInnerWrapper = styled('Box')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  }))

export const StyledModalHeader = styled('Box')(({ theme, headerBackgroundColor }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    alignItems: 'center',
    width: '100%',
    height: '30%',
    minHeight: '250px', // Fix duplicate height and minHeight
    borderBottom: '1px solid #000',
    backgroundColor: headerBackgroundColor || theme.palette.secondary.main,
    justifyContent: 'center',
    spacing: '10px',
  }))

export const StyledModalBody = styled('Box')(({ theme }) => ({
    marginTop: '10px',
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    width: '95%',
    overflow: 'scroll',
    padding: '4px',
  }))

export const StyledModalFooter = styled('Box')(({ theme, headerBackgroundColor }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    padding: '4px',
    marginTop: 'auto',
    width: '342px',
    borderTop: '1px solid #000',
    backgroundColor: headerBackgroundColor || theme.palette.secondary.main,
  }))