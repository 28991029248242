import { useEffect, useState } from 'react';

export function simpleHash(str) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
  }
  return hash;
}

export function getColor(str) {
    if (!str) return 'hsl(0, 60%, 80%)';
    const hash = simpleHash(str);
    const hue = Math.abs(hash % 360);
    return `hsl(${hue}, 60%, 80%)`;
}

const useIsTouchscreen = () => {
  const [isTouchscreen, setIsTouchscreen] = useState(false);

  useEffect(() => {
    const handleTouchStart = () => {
      setIsTouchscreen(true);
      window.removeEventListener('touchstart', handleTouchStart);
    };

    window.addEventListener('touchstart', handleTouchStart);

    return () => {
      window.removeEventListener('touchstart', handleTouchStart);
    };
  }, []);

  return isTouchscreen;
};

export default useIsTouchscreen;