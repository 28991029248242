import React, {useState, useEffect} from "react";
import { gql, useQuery } from "@apollo/client";
import { Paper, Typography, Grid, Snackbar, Drawer, DialogContent, Button, styled, IconButton } from "@mui/material";

import MuiAlert from "@mui/material/Alert";
import useFitText from "use-fit-text";
import { getColor } from "./utils";
import { set } from "lodash";
import { useTheme } from "@emotion/react";
import { SnackbarProvider, useSnackbar } from 'notistack';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import BingoCard from "./BingoCard";

const GET_BINGO_CARDS = gql`
    query getBingoCards {
        getBingoCards {
            _id
            playerName
            cardSpaces {
                _id
                text
                status
                updatedAt
            }
        }
    }
`;


const Square = ({ onClick, text, status, lastUpdated }) => {



  return (
    <div>
      <Paper
        elevation={2}
        onClick={() => {
          onClick();
        }}
        style={{
          width: "8px",
          maxWidth: "12vh",
          height: "8px",
          maxHeight: "12vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          background: "white",
          cursor: "pointer",
          backgroundColor: status ? "green" : "white",
        }}
      >
      </Paper>
    </div>
  );
};


const SmallBingoCard = ({ bingoCard }) => {
  const { playerName, cardSpaces } = bingoCard || {};

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [lastUpdatedSpace, setLastUpdatedSpace] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  const handleSnackbarOpen = () => {
    setOpenSnackbar(true);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  useEffect(() => {
    if (cardSpaces && cardSpaces.length > 0) {
      const mostRecentlyUpdatedSpace = cardSpaces.flat().reduce((acc, space) => {
        if (space.updatedAt > acc.updatedAt) {
          return space;
        }
        return acc;
      }, { updatedAt: new Date(0) });

      setLastUpdatedSpace(mostRecentlyUpdatedSpace);
      if (
        mostRecentlyUpdatedSpace &&
        Date.now() - mostRecentlyUpdatedSpace.updatedAt < 5000 &&
        Date.now() - mostRecentlyUpdatedSpace.updatedAt > 0
      ) {
        enqueueSnackbar(`${playerName} ${mostRecentlyUpdatedSpace?.status ? "marked" : "removed"}: ${mostRecentlyUpdatedSpace?.text}`, { variant: mostRecentlyUpdatedSpace?.status ? "success" : "error" });
      }
    }
  }, [cardSpaces]);

  if (!cardSpaces) return null;

  return (
    <div>
      <Paper
        onClick={() => {}}
        elevation={3}
        style={{
          backgroundColor: getColor(playerName),
          padding: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "200px",
        }}
      >
        <Typography variant="h5" style={{ marginBottom: 10 }}>
          {`${playerName}`}
        </Typography>
        <Grid container spacing={1} style={{ display: "flex", justifyContent: "center" }}>
          {cardSpaces.map((row, rowIndex) => (
            <Grid container item key={rowIndex} spacing={1} style={{ display: "flex", justifyContent: "center" }}>
              {row.map((space, colIndex) => (
                <Grid item key={colIndex}>
                  <Square text={space.text} status={space.status} />
                </Grid>
              ))}
            </Grid>
          ))}
        </Grid>
      </Paper>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose}>
          {playerName} {lastUpdatedSpace?.status ? "marked" : "removed"}: {lastUpdatedSpace?.text}
        </Alert>
      </Snackbar>
    </div>
  );
};

const BingoDrawer = ({ bingoCard}) => {

}

const ArrowButton = styled(IconButton)(({ theme, direction }) => ({
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  zIndex: 1000,
  color: theme.palette.primary.main, // Change color as needed
}));

const OtherBingo = ({bingoCard: playerCard}) => {
    const { data } = useQuery(GET_BINGO_CARDS, { pollInterval: 3000 });
  const theme = useTheme();

  const scrollContainerRef = React.createRef();

  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollLeft -= 200; // Adjust the scroll amount as needed
    }
  };

  const scrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollLeft += 200; // Adjust the scroll amount as needed
    }
  };

  const [open, setOpen] = React.useState(false);
  const [bingoCard, setBingoCard] = React.useState(null);

  return (
    <SnackbarProvider maxSnack={3}>
      <div style={{ position: 'relative' }}>
        <ArrowButton onClick={scrollLeft} style={{ left: 0 }}>
          <KeyboardArrowLeftIcon />
        </ArrowButton>
        <ArrowButton onClick={scrollRight} style={{ right: 0 }}>
          <KeyboardArrowRightIcon />
        </ArrowButton>
        <div
          ref={scrollContainerRef}
          style={{ display: 'flex', overflowX: 'auto', width: '100vw', backgroundColor: theme.palette.info.main,  scrollBehavior: 'smooth', // Enable smooth scrolling
         }}
        >
          {data &&
            data.getBingoCards.map((bingoCard) => (playerCard?._id !== bingoCard._id) && (
              <div 
              key={bingoCard._id} 
              style={{ width: '200px', margin: '16px', cursor: 'pointer' }}
              onClick={() => {
                setOpen(true)
                setBingoCard(bingoCard)
              }
            }
              
              >
                <SmallBingoCard bingoCard={bingoCard} />
              </div>
            ))}
        </div>
         <Drawer
            anchor={"bottom"}
            open={open}
            onClose={() => setOpen(false)}
          >
            <div>
            <BingoCard bingoCard={bingoCard} viewMode fullWidth />
            </div>
        </Drawer>
      </div>
    </SnackbarProvider>
  );
};

export default OtherBingo;