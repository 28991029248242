import React, { useEffect, useState } from "react";
import { Paper, Typography, Grid, Dialog, DialogTitle, DialogContent, Button } from "@mui/material";
import { gql, useMutation } from '@apollo/client';
import useFitText from "use-fit-text";
import { getColor } from "./utils";


const UPDATE_BINGO_CARD = gql`
mutation UpdateBingoCard($input: updateBingoCardInput) {
    updateBingoCard(input: $input) {
        _id
        playerName
        cardSpaces {
            _id
            text
            status
            updatedAt
        }
    }
}
`;

const Square = ({status, onClick, text}) => {
    const { fontSize, ref } = useFitText({minFontSize: 1, });
    const screenWidth = window.innerWidth;
    let fontSize2 = "7px"
    return (
        <Paper
        ref={ref}
        elevation={2}
        onClick={onClick}
        style={{
            width: "15vw",
            maxWidth: "12vh",
            height: "15vw",
            maxHeight: "12vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "white",
            cursor: "pointer",
            fontSize: (text.length > 50 && screenWidth < 500) ? fontSize2 : fontSize,
            backgroundColor: status ? "green" : "white",
            textAlign: "center",
        }}
        >
        <p style={{ padding: "2px"}}>{text}</p>
        </Paper>
    );
}

const BingoCard = ({ bingoCard, viewMode, fullWidth }) => {
  const { playerName, cardSpaces } = bingoCard || {};
  const [updatedCardSpaces, setUpdatedCardSpaces] = useState(cardSpaces);
  const [updateBingoCardMutation] = useMutation(UPDATE_BINGO_CARD);

  useEffect(() => {
    setUpdatedCardSpaces(cardSpaces);
  }, [cardSpaces]);

  const handleSquareClick = async (space, rowIndex, colIndex) => {
    if (viewMode) return;
    const updatedCardSpacesCopy = [...updatedCardSpaces];
    const newStatus = !updatedCardSpacesCopy[rowIndex][colIndex].status
    updatedCardSpacesCopy[rowIndex][colIndex].status = newStatus;
    setUpdatedCardSpaces(updatedCardSpacesCopy);
    console.log({space})
    try {    
        await updateBingoCardMutation({ variables: { input: { cardId: bingoCard._id, cardSpaceId: space._id, status: newStatus}}});
    } catch (error) {
        console.error('Error updating Bingo Card:', error.message);
        updatedCardSpacesCopy[rowIndex][colIndex].status = !newStatus;
        setUpdatedCardSpaces(updatedCardSpacesCopy);
    }
  };




  if (!updatedCardSpaces) return null

  return (
    <Paper 
        elevation={3} 
        style={{ 
            backgroundColor: getColor(playerName),
            ...fullWidth ? {width:"100vw"} : {maxWidth: "65vh"} , 
            ...viewMode ? {paddingBottom: "16px"} : {padding: "16px"}, 
            display: 'flex', 
            flexDirection: 'column', 
            alignItems: 'center', 
            justifyContent: 'center',
        }}>
      <Typography variant="h5" style={{ marginBottom: 10 }}>{`Player: ${playerName}`}</Typography>
      <Grid container spacing={1} style={{ display: 'flex', justifyContent: 'center' }}>
        {updatedCardSpaces.map((row, rowIndex) => (
          <Grid container item key={rowIndex} spacing={1} style={{ display: 'flex', justifyContent: 'center' }}>
            {row.map((space, colIndex) => (
              <Grid item key={colIndex}>
                <Square
                  onClick={() => handleSquareClick(space, rowIndex, colIndex)}
                  text={space.text}
                  status={space.status}
                />
              </Grid>
            ))}
          </Grid>
        ))}
      </Grid>
    </Paper>
  );
};

export default BingoCard;
