import React from 'react';
import { styled } from '@mui/material/styles';
import { grey } from '@mui/material/colors';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { Global } from '@emotion/react';
import { getColor } from '../../Graph/utils';
import { StyledModalHeader } from '../../Graph/GraphModal/GraphModal';
import { Avatar, Button, Divider, Drawer } from '@mui/material';

const imageStyle = {
  width: 100,
  height: 100,
  margin: '0 auto',
  display: 'block',
  cursor: 'pointer',
};
const imageStyleSmall = {
  width: 32,
  height: 32,
  margin: '0',
  display: 'block',
  cursor: 'pointer',
};

const Root = styled('div')(({ theme }) => ({
  height: '100%',
  backgroundColor:
    theme.palette.mode === 'light' ? grey[100] : theme.palette.background.default,
}));

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'light' ? '#fff' : grey[800],
}));

const Puller = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 8,
  left: 'calc(50% - 15px)',
  zIndex: 2500,
}));

export const EdgeDrawer = ({ selectedEdge, onClose, person1, person2, open, toggleDrawer, onPersonClick }) => {
  const drawerBleeding = selectedEdge ? 56 : 56;

  console.log({person1})
  return (
    <Root>
      <Global
        styles={{
          '.MuiDrawer-root > .MuiPaper-root': {
            height: `calc(80% - ${drawerBleeding}px)`,
            overflow: 'visible',
          },
        }}
      />
      <Drawer
        anchor="bottom"
        open={open}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        swipeAreaWidth={drawerBleeding}
        disableSwipeToOpen={false}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <StyledBox
          onClick={toggleDrawer(!open)}
          sx={{
            position: 'absolute',
            top: -(selectedEdge ? 56 : 0),
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            visibility: 'visible',
            right: 0,
            left: 0,
            zIndex: 2000,
            backgroundImage: selectedEdge?._id
              ? `linear-gradient(to right, ${getColor(
                  person1._id
                )}, ${getColor(person2._id)})`
              : '',
            transition: 'top 0.2s ease-out',
          }}
        >
          <Puller>
            <IconButton onClick={toggleDrawer(!open)}>
              {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </Puller>
          <Box
            sx={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <Typography sx={{ p: 2, color: 'text.secondary', fontWeight: 'bold' }}>
              {person1?.name}
            </Typography>
            <Typography sx={{ p: 2, color: 'text.secondary', fontWeight: 'bold' }}>
              {person2?.name}
            </Typography>
          </Box>
        </StyledBox>
        <StyledBox
          sx={{
            pb: 2,
            height: '100%',
            overflow: 'auto',
            zIndex: 2000,
          }}
        >
        <Box cx={{   
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
            height: '30%',
            minHeight: '250px', // Fix duplicate height and minHeight
            borderBottom: '1px solid #000',
            justifyContent: 'center',
        }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                backgroundImage: selectedEdge?._id ? `linear-gradient(to right, ${getColor(person1._id)}, ${getColor(person2._id)})` : "",
                paddingBottom: '16px',
                paddingRight:"10px",
                paddingLeft:"10px"
              }}
            >
                <Box>
                    <IconButton>
                <Avatar
                  alt={person1?.name}
                  src={person1?.imageURL || `https://robohash.org/${person1?.name}`}
                  sx={imageStyle}
                  onClick={() => onPersonClick(person1)}
                />  
                </IconButton>
                </Box>
                <Box>
                    <IconButton>
              <Avatar
                  alt={person2?.name}
                  src={person2?.imageURL || `https://robohash.org/${person2?.name}`}
                  sx={imageStyle}
                  onClick={() => onPersonClick(person2)}
                />
                </IconButton>
                </Box>
             
            </Box>
            <Box sx={{padding:"10px", gap:"2"}}>
                <Typography variant="body1" sx={{ color: 'text.primary', fontWeight: 'bold', marginBottom: 2 }}>
                Met: {selectedEdge?.dateMet ? new Date(selectedEdge?.dateMet).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' }) : ''}
                </Typography>
                <Typography variant="body1" sx={{ color: 'text.primary', fontWeight: 'bold', marginBottom: 2 }}>
                TLDR: {selectedEdge?.notes}
                </Typography>
                {console.log(selectedEdge)}
                {selectedEdge?.interviewQuestions?.map((question) => (
                    <Box sx={{display:'flex', flexDirection:"column", marginBottom: "16px"}}>
                        <Typography variant="body1" sx={{ color: 'text.primary', fontWeight: 'bold', marginBottom: 2 }}>
                            {question.question}
                        </Typography>
                        <Box
                        sx={{
                            display: 'flex',
                            gap: 2, // Adjust the gap between Avatar and Typography
                            color: 'text.primary',
                            backgroundColor: getColor(question.personId),
                            padding:'4px',
                            borderRadius: '4px',
                            alignItems: 'center',

                        }}
                        >
                        {person1._id === question.personId ? <Avatar
                            alt={person1?.name}
                            src={person1?.imageURL || `https://robohash.org/${person1?.name}`}
                            sx={imageStyleSmall} 
                        /> :  <Avatar
                            alt={person2?.name}
                            src={person2?.imageURL || `https://robohash.org/${person2?.name}`}
                            sx={imageStyleSmall} 
                        /> }
                        <Typography>
                            {question.answer}
                        </Typography>
                        </Box>

                    </Box>
                ))}
            </Box>
          </Box>
            

        </StyledBox>
    </Drawer>
    </Root>
  );
}
