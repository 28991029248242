import React, { useEffect, useState } from 'react';
import './styles.css';
import { gql, useMutation } from '@apollo/client';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import { Typography } from '@mui/material';
import { StyledModal, StyledModalBody, StyledModalFooter, StyledModalHeader, modalStyles } from '../GraphModal/GraphModal';
import { CloudinaryUpload } from '../../CloudinaryUpload';

const EDIT_PERSON_MUTATION = gql`
  mutation EditPerson($editPersonId: ID!, $name: String!, $imageURL: String, $funFact: String) {
    editPerson(id: $editPersonId, name: $name, imageURL: $imageURL, funFact: $funFact) {
      _id
    }
  }
`;

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 350,
  height: '80%',
  bgcolor: 'background.paper',
  border: '2px solid theme.palette.background.paper',
  boxShadow: 24,
};

export const EditPersonModal = ({
  selectedNode,
  isModalOpen,
  setIsModalOpen,
  refetchGraph,
  headerBackgroundColor,
}) => {
  const [editPerson] = useMutation(EDIT_PERSON_MUTATION);
  const [editedNodeName, setEditedNodeName] = useState('');
  const [editedNodeImage, setEditedNodeImage] = useState('');
  const [editedFunFact, setEditedFunFact] = useState('');

  const saveChanges = async () => {
    // Update the node with the edited name, image, and funFact
    await editPerson({
      variables: {
        editPersonId: selectedNode.id,
        name: editedNodeName,
        imageURL: editedNodeImage,
        funFact: editedFunFact,
      },
    });
    await refetchGraph();
    setIsModalOpen(false);
  };

  useEffect(() => {
    setEditedNodeName(selectedNode?.name);
    setEditedNodeImage(selectedNode?.image);
    setEditedFunFact(selectedNode?.funFact);
  }, [selectedNode]);

  if (!selectedNode || !isModalOpen) return null;

  return (
    <Modal
      open={isModalOpen}
      onClose={() => setIsModalOpen(false)}
    >
      <StyledModal>
        <StyledModalHeader headerBackgroundColor={headerBackgroundColor}>
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            marginBottom: '50px',
          }}>
            <Avatar alt={selectedNode.name} src={editedNodeImage} style={{ width: "150px", height: "150px" }} />
            <Typography variant="h5">{selectedNode.name}</Typography>
          </Box>
        </StyledModalHeader>

        <StyledModalBody>
          <TextField
            type="text"
            value={editedNodeName}
            onChange={(e) => setEditedNodeName(e.target.value)}
            label="Name"
          />
          <TextField
            type="text"
            value={editedNodeImage}
            onChange={(e) => setEditedNodeImage(e.target.value)}
            label="Image URL"
          />
          <TextField
            type="text"
            value={editedFunFact}
            onChange={(e) => setEditedFunFact(e.target.value)}
            label="Fun Fact"
          />
          <CloudinaryUpload
            imageUrl={editedNodeImage}
            setImageUrl={setEditedNodeImage}
          />
        </StyledModalBody>

        <StyledModalFooter headerBackgroundColor={headerBackgroundColor}>
          <Button variant="contained" onClick={saveChanges}>Save</Button>
          <Button variant="contained" onClick={() => setIsModalOpen(false)}>Cancel</Button>
        </StyledModalFooter>
      </StyledModal>
    </Modal>
  );
};
