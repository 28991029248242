import { createTheme} from '@mui/material/styles';

export const muiTheme = createTheme({
  palette: {
    primary: {
      main: '#ef476fff', // bright-pink-crayola
    },
    secondary: {
      main: '#ffd166ff', // sunglow
    },
    success: {
      main: '#06d6a0ff', // emerald
    },
    info: {
      main: '#118ab2ff', // blue-ncs
    },
    error: {
      main: '#073b4cff', // midnight-green
    },
  },
});