import React, { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import { Autocomplete, Typography, Divider, useTheme } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {StyledModal, StyledModalBody, StyledModalFooter, StyledModalHeader, StyledModalInnerWrapper, modalStyles } from '../GraphModal/GraphModal';

const ADD_PERSON_EDGE_MUTATION = gql`
  mutation AddPersonEdge($name: String!, $person2: ID!, $notes: String!, $dateMet: Date!) {
  addPersonEdge(name: $name, person2: $person2, notes: $notes, dateMet: $dateMet) {
    _id   
  }
}
`;


const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 350,
  height: '80%',
  bgcolor: 'background.paper',
  border: '2px solid theme.palette.background.paper',
  boxShadow: 24,
};

const imageStyle = {
  width: 100,
  height: 100,
  margin: '0 auto',
  display: 'block',
};

const dashedLineStyle = {
  border: '1px dashed #000',
  width: '100%',
  height: '0px',
};

const style = {
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  alignItems: 'center',
  width: '100%',
  height: '100%',
};

export const CreatePersonModal = ({
  people,
  isModalOpen,
  setIsModalOpen,
  refetchGraph,
}) => {
  const [addPersonEdge] = useMutation(ADD_PERSON_EDGE_MUTATION);
  const theme = useTheme()
  const [step, setStep] = useState(0);

  const [editedNodeName, setEditedNodeName] = useState('');
  const [editedNodeImage, setEditedNodeImage] = useState('');

  const [editedEdgeNote, setEditedEdgeNote] = useState('');
  const [editedEdgeDateMet, setEditedEdgeDateMet] = useState();

  const [person2, setPerson2] = useState(null);


  const handleAddPerson = async () => {
    if (editedNodeName  && person2 && editedEdgeNote && editedEdgeDateMet) {
      await addPersonEdge({
        variables: { name: editedNodeName, imageURL: editedNodeImage, person2: person2._id, notes: editedEdgeNote, dateMet: editedEdgeDateMet },
      });
      await refetchGraph();
      setStep(0);
      setIsModalOpen(false);
      setEditedNodeName('');
      setEditedNodeImage('');
      setPerson2(null);
      setEditedEdgeDateMet(null);
      setEditedEdgeNote('');
    }
  };

  const handleClose = () => {
    setStep(0);
    setIsModalOpen(false);
  };

  const handlePrevious = () => {
    setStep((x) => x - 1);
  };

  const handleNext = () => {
    if (step === 0) {
      setStep(1);
    } else {
      handleAddPerson();
    }
  };

  if (!isModalOpen) return null;

  return (
    <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
      <StyledModal>
        {step === 0 && (
          <StyledModalInnerWrapper>
            <StyledModalHeader>
              <Avatar
                alt="avatar"
                src={editedNodeImage}
                style={{ width: '150px', height: '150px', marginTop: '10px', }}
              />
              <Typography variant="h5">
                {editedNodeName || 'Jane Doe'}
              </Typography>
            </StyledModalHeader>

            <StyledModalBody>
              <TextField
                type="text"
                value={editedNodeName}
                onChange={(e) => setEditedNodeName(e.target.value)}
                label="Name"
                width="300px"
                sx={{ width: '100%' }}
              />
              <TextField
                type="text"
                value={editedNodeImage}
                onChange={(e) => setEditedNodeImage(e.target.value)}
                label="Image URL"
                sx={{ width: '100%' }}
              />
              <Autocomplete
                id="combo-box-demo"
                options={people}
                getOptionLabel={(person) => person.name}
                onChange={(e, value) => setPerson2(value)}
                renderInput={(params) => (
                  <TextField {...params} label="Who do you know?" sx={{ width: '100%' }} />
                )}
              />
            </StyledModalBody>
           <StyledModalFooter>
              <Button variant="contained" color="primary" onClick={handleNext}>Next</Button>
              <Button variant="contained" color="primary" onClick={handleClose}> Cancel </Button>
            </StyledModalFooter>
          </StyledModalInnerWrapper>
        )}
        {step === 1 && (
          <StyledModalInnerWrapper>
            <StyledModalHeader>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: "20px",
                width: "95%",
                height:"230px",
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '10px',
                  alignItems: 'center',
                }}
              >
                <Avatar
                  alt={editedNodeName}
                  src={editedNodeImage || `https://robohash.org/${editedNodeName}`}
                  sx={imageStyle}
                />
                <Typography variant="h5" align="center" gutterBottom>
                  {editedNodeName}
                </Typography>
              </Box>
              <Box
                flexItem
                sx={{
                  display: 'flex',
                  width: '80%',
                  flexDirection: 'column',
                  alignItems: 'center',
                  marginTop: '50px',
                }}
              >
                <Divider style={dashedLineStyle} flexItem />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '10px',
                  alignItems: 'center',
                }}
              >
                <Avatar
                  alt={person2?.name}
                  src={person2?.imageURL || `https://robohash.org/${person2?.name}`}
                  sx={imageStyle}
                />
                <Typography variant="h5" align="center">
                  {person2?.name}
                </Typography>
              </Box>
            </Box>
            </StyledModalHeader>

            <StyledModalBody>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker  sx={{ width: '100%' }} label="When Did You Meet?" onChange={(e) => setEditedEdgeDateMet(e)} />
              </LocalizationProvider>
              <TextField
                type="text"
                multiline
                minRows={4}
                value={editedEdgeNote}
                onChange={(e) => setEditedEdgeNote(e.target.value)}
                label="How We Met"
                 sx={{ width: '100%' }}
              />
              
            </StyledModalBody>

            <StyledModalFooter sx={{ display: 'flex', justifyContent: 'space-between', padding: "4px", marginTop: 'auto', width: "342px", borderTop: '1px solid #000', backgroundColor:theme.error, }}>
              <Button variant="contained" onClick={handleNext}>Save</Button>
              <Button variant="contained" onClick={handlePrevious}>Previous</Button>
            </StyledModalFooter>
          </StyledModalInnerWrapper>
        )}
      </StyledModal>
    </Modal>
  );
};
