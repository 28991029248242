import React from 'react';
import { styled, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { BrowserRouter, Routes, Route, Link as RouterLink, HashRouter } from 'react-router-dom';
import Graph from './Graph/Graph';
import GraphRead from './GraphRead/GraphRead';
import { muiTheme } from './muiTheme';
import logo from './logo512.png';
import { Bingo } from './Bingo/Bingo';

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
  backgroundColor: "black"
}));

const Navbar = styled(AppBar)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
}));

const Logo = styled('img')({
  height: '40px', // Adjust the height as needed
  marginRight: '8px', // Adjust the margin as needed
});

function App() {
  return (
    <HashRouter>
    <ThemeProvider theme={muiTheme}>
      <Root>
        {/* <Navbar position="sticky" sx={{height:"32px"}}>
          <Toolbar sx={{height:"32px"}}>
            <IconButton component={RouterLink} to="/" sx={{position:'absolute'}}>
              <Logo src={logo} alt="Logo" />
            </IconButton>
            <Typography variant="h8" component="div" sx={{ flexGrow: 1, textAlign: 'center' }}>
              How You Met Me
            </Typography>
          </Toolbar>
        </Navbar> */}
          <Routes>
            <Route path="/edit" element={<Graph />} />
            <Route path="/graph" element={<GraphRead />} />
            <Route path="/" element={<Bingo />} />
          </Routes>
      </Root>
    </ThemeProvider>
    </HashRouter>
  );
}

export default App;
