import { useEffect, useState } from 'react';

export function simpleHash(str) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
  }
  return hash;
}

export function getColor(str) {
    const hash = simpleHash(str);
    const hue = Math.abs(hash % 360);
    return `hsl(${hue}, 60%, 80%)`;
}

const useIsTouchscreen = () => {
  const [isTouchscreen, setIsTouchscreen] = useState(false);

  useEffect(() => {
    const handleTouchStart = () => {
      setIsTouchscreen(true);
      window.removeEventListener('touchstart', handleTouchStart);
    };

    window.addEventListener('touchstart', handleTouchStart);

    return () => {
      window.removeEventListener('touchstart', handleTouchStart);
    };
  }, []);

  return isTouchscreen;
};

export default useIsTouchscreen;

export function mapPersonToEdges(people, edges) {
  const personToEdgesMap = {};

  // Iterate through each edge
  edges.forEach((edge) => {
    // Get the person IDs from the edge
    const person1Id = edge.person1;
    const person2Id = edge.person2;

    // Map the edge to person1
    if (!personToEdgesMap[person1Id]) {
      personToEdgesMap[person1Id] = [edge];
    } else {
      personToEdgesMap[person1Id].push(edge);
    }

    // Map the edge to person2
    if (!personToEdgesMap[person2Id]) {
      personToEdgesMap[person2Id] = [edge];
    } else {
      personToEdgesMap[person2Id].push(edge);
    }
  });

  return personToEdgesMap;
}