import React, { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { Button, TextField, Typography, Paper, Container } from '@mui/material';

const CREATE_BINGO_CARD = gql`
    mutation CreateBingoCard($playerName: String!) {
        createBingoCard(input: { playerName: $playerName }) {
            _id
            playerName
            cardSpaces {
                _id
                text
                status
                updatedAt
            }
        }
    }
`;

const BingoCardForm = ({setBingoCard}) => {
  const [playerName, setPlayerName] = useState('');
  const [createBingoCardMutation] = useMutation(CREATE_BINGO_CARD);

  const handlePlayerNameChange = (event) => {
    setPlayerName(event.target.value);
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    try {
      const { data } = await createBingoCardMutation({
        variables: { playerName },
      });

      console.log('New Bingo Card:', data.createBingoCard);
      setBingoCard(data.createBingoCard);

      // Handle success, e.g., show a success message or redirect
    } catch (error) {
      console.error('Error creating Bingo Card:', error.message);
      // Handle error, e.g., show an error message to the user
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Paper elevation={3} style={{ padding: 20, marginTop: 50 }}>
        <Typography variant="h5">Enter Player's Name</Typography>
        <form onSubmit={handleFormSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            label="Player's Name"
            value={playerName}
            onChange={handlePlayerNameChange}
          />
          <Button type="submit" variant="contained" color="primary" fullWidth>
            Get Bingo Card
          </Button>
        </form>
      </Paper>
    </Container>
  );
};

export default BingoCardForm;
